import { IDLE } from '@/shared/constants/loadingStatuses.constants';

export default {
    chargebeeInitialized: false,
    subscriptionInfo: {
        edition: '',
        limits: {},
    },
    renewalEstimate: {
        scheduledChangesEstimate: {},
        estimate: {
            lineItems: [
                {
                    name: '',
                },
            ],
        },
    },
    subscriptionInfoLoading: IDLE,
    renewalEstimateLoading: IDLE,
    cancelScheduledChangesLoadingStatus: IDLE,
    removeScheduledCancellationLoadingStatus: IDLE,
    cancelRequestLoadingStatus: IDLE,
    usagesLoadingStatus: IDLE,
    usages: {
        crmUsages: {},
    },
    contactLimitAlertsIgnored: false,
    hasManageBillingPermission: false,
    manageBillingPermissionLoading: IDLE,
    addonsPricing: {
        additionalUsers: {
            priceTiers: [{}],
            metadata: {
                tiers: [{}],
            },
        },
        additionalContacts: {
            priceTiers: [{}],
            metadata: {
                tiers: [{}],
            },
        },
        keapTextAndVoice: {
            priceTiers: [{}],
            metadata: {
                tiers: [{}],
            },
        },
        additionalSms: {
            priceTiers: [{}],
            metadata: {
                tiers: [{}],
            },
        },
    },
    addonsPricingLoading: IDLE,
    selectedUsersTier: 0,
    selectedContactsTier: 0,
    selectedKtvTier: 0,
    selectedMessageBundleTier: 0,
    editSubscriptionAddonsStageLoading: IDLE,
};
